export default [
    {
      path: '/user-management',
      name: 'user-management',
      component: () => import('@/views/user-management/UserManagement.vue'),
    },
    {
      path: '/user-cancel',
      name: 'user-cancel',
      component: () => import('@/views/user-cancel/UserCancel.vue'),
    },
  ]
  