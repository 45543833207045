import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  faqData: {
    terms: {
      title: 'Geral',
      subtitle: '',
      qandA: [
        {
          question: 'Quais são os requisitos para usar o Dialog?',
          ans: 
            `Você vai precisar do seguinte::
            <br> <br> •	Um equipamento Mercatus SENSE conectado a uma rede Wi-Fi;
            <br> •	Um PC/tablet/smartphone para aceder ao Dialog Web App via código QR ou link.` 
        },
        {
          question: 'Como conectar e sincronizar meu equipamento pela primeira vez?',
          ans:`
          No Dialog você pode ter vários equipamentos sincronizados na mesma conta.
          <br> <br> Use a etiqueta de códigos QR e siga as três etapas, depois registe uma conta (utilizadores iniciantes) ou faça login na sua conta. A sincronização é imediata. 
          <br> <br>💡Dica: Fornecemos duas etiquetas QR-code, uma dentro do equipamento ao lado da placa de características e outra com o manual de instruções. Caso não encontre as etiquetas de código QR, contacte o seu fornecedor.
          `,
        },
        {
          question: 'Como posso entrar em contacto com a equipa de suporte?',
          ans:`📝 Utilize o formulário de contacto na secção Contactos do site
                   <br> <br> <a href=""><b> Ir para o formulário de contacto <b/> <a>
          `,
        },
      ],
    },
    privacy: {
      title: 'Conta',
      subtitle: '',
      qandA: [
        {
          question: 'Como crio a minha conta?',
          ans:
            'Criar uma conta no Dialog demora apenas alguns minutos. Ao aceder ao Dialog pela primeira vez, preencha o formulário de registo, valide o seu email e está pronto.',
        },
        {
          question:'Como aceder ao Dialog Web App?',
          ans:
            `Digitalize o código QR ou introduza no navegador de internet o endereço fornecido na etiqueta dos códigos QR. 
            <br><br>
            O seu equipamento deverá estar previamente conectado e sincronizado com o Dialog, veja como o fazer <a href=""><b>aqui.</b></a>.
            <br><br>
            💡Dica: Fornecemos duas etiquetas com códigos QR, uma dentro do equipamento ao lado da placa de características e outra com o manual de instruções. Caso não encontre as etiquetas de código QR, contacte o seu fornecedor.
            `,
        },
        {
          question: 'E se eu perder/esquecer os meus dados de acesso?',
          ans:
            `Aceda a https://iot.mercatus.pt/ e siga estes passos:
            <br><br>
            •	Selecione Esqueceu a senha?
            <br>
            •	Introduza o seu endereço de email para receber um código de verificação na sua conta de email.
            <br>
            •	Verifique a sua conta de email e copie o código de verificação. 
            <br>
            •	Cole o código de verificação no campo apropriado e defina uma nova senha.
            `,
        },
        {
          question: 'Como posso eliminar a minha conta?',
          ans:`Lamentamos que queira eliminar a sua conta do Dialog! Para eliminar a sua conta, siga estas etapas:
          <br><br>
          •	Passe o cursor do rato sobre o nome da sua conta ou sobre a imagem no canto superior direito do ecrã;
          <br>
          •	No menu de lista pendente, selecione Minha conta > separador Conta > secção Detalhes do perfil;
          <br>
          •	Vá até ao final da página para Eliminar a conta e selecione o botão Desativar conta.
          `,
        },
        {
          question: 'Como posso alterar o endereço de email associado à minha conta/assinatura registada?',
          ans:
            `Aceda à sua conta e proceda da seguinte forma:
            <br><br>
            •	Passe o cursor do rato sobre o nome da sua conta ou sobre a imagem no canto superior direito do ecrã;
            <br>
            •	No menu de lista pendente, selecione Minha conta > separador Conta > secção Detalhes do perfil;
            <br>
            •	Altere o endereço de email e guarde.
            `,
        },
      ],
    },
    cookie: {
      title: 'Subscrição, pagamento e faturação',
      subtitle: '',
      qandA: [
        {
          question: 'Como posso subscrever o Dialog Premium?',
          ans:
            `Aceda ao Dialog Web App e proceda da seguinte form:
            <br><br>
            •	Passe o cursor do rato sobre o nome da sua conta ou imagem no canto superior direito do ecrã;
            <br>
            •	No menu de lista pendente selecione Minha conta > separador Planos;
            <br>
            •	Selecione a opção de assinatura pretendida.
            `,
        },
        {
          question: 'Como posso renovar a minha assinatura?',
          ans: `Todas as assinaturas são renovadas automaticamente. `,
        },
        {
          question: 'Quais são as formas de pagamento disponíveis?',
          ans: `Todos os pagamentos podem ser feitos com cartão de crédito ou PayPal. Ao efetuar o pagamento dessa forma autoriza-nos a receber o seu pagamento anualmente ou mensalmente.`,
        },
        {
          question: 'Onde posso encontrar a minha fatura?',
          ans:`Para encontrar e descarregar a sua fatura:
          <br><br>
          •	Passe o cursor do rato sobre o nome da sua conta ou sobre a imagem no canto superior direito do ecrã;
          <br>
          •	No menu de lista pendente, selecione Minha conta > separador Planos > secção Faturas;
          <br>
          •	Clique em Download ao lado da fatura que deseja descarregar.
          <br><br>
          💡Dica: Também efetuamos o envio para sua conta de email.
          `
        },
        {
          question: 'Onde posso adicionar o meu Número de Identificação Fiscal (NIF)?',
          ans: `Se tiver a necessidade de ter um NIF na fatura/recibo, preencha-o no formulário de pagamento durante o processo de subscrição.`,
        },
        {
          question: 'Os preços apresentados incluem ou excluem impostos?',
          ans:
            'Todos os preços exibidos nas opções de assinatura não incluem IVA.',
        },
        {
          question: 'Como posso cancelar a minha subscrição?',
          ans:
            `Pode cancelar a sua subscrição a qualquer momento e continuar a ter acesso durante todo o período de assinatura. Para cancelar, proceda da seguinte forma:
            <br><br>
            •	Passe o cursor rato sobre o nome da sua conta ou sobre a imagem no canto superior direito do ecrã;
            <br>
            •	No menu de lista pendente, selecione Minha conta > separador Planos;
            <br>
            •	Selecione o botão Cancelar assinatura e siga os passos.
            `,
        },
      ],
    },
    faqs: {
      title: 'Gestão de acesso dos utilizadores',
      subtitle: '',
      qandA: [
        {
          question: 'Quem é o administrador do equipamento?',
          ans:
            'O primeiro utilizador que sincroniza o equipamento com o Dialog torna-se o administrador. No entanto, o administrador pode optar por reatribuir essa função a outro utilizador.',
        },
        {
          question: 'Como o administrador pode permitir que outros utilizadores tenham acesso ao equipamento?',
          ans:
            `•	Aceda ao menu Gestão de utilizadores;
            <br>
            •	As solicitações de acesso pendentes aparecerão no topo da página;
            <br>
            •	Pressione ✔️ para aceitar ou ❌ para negar o acesso;
            <br>
            •	Caso aceite, o utilizador receberá um aviso a informar que sua solicitação foi aceite.
            `,
        },
        {
          question: 'Como é que o administrador partilha remotamente o acesso ao equipamento?',
          ans:
            `Se desejar partilhar o acesso ao seu equipamento com novos utilizadores, faça o seguinte:
            <br><br>
            •	Aceda ao menu Gestão de utilizadores;
            <br>
            •	Copie o endereço disponibilizado e partilhe com o novo utilizador;
            <br>
            •	O novo utilizador em seguida necessita de aceder ao Dialog através do endereço compartilhado;
            <br>
            •	Voltar ao menu Gestão de utilizadores;
            <br>
            •	As solicitações de acesso pendentes aparecerão no topo da página;
            <br>
            •	Pressione ✔️ para aceitar ou ❌ para negar o acesso;
            <br>
            •	Caso aceite, o utilizador receberá um aviso a informar que sua solicitação foi aceite.
            `,
        },
        {
          question: 'Quantos utilizadores podem aceder a cada equipamento?',
          ans:
            'No Dialog não há limite para o número de utilizadores. No entanto, cada utilizador deve ser autorizado pelo administrador do equipamento. ',
        },
      ],
    },
  },
}

mock.onGet('/faq/data/pt').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    // eslint-disable-next-line arrow-body-style
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
