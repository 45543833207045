const recipeEndpoint = `${process.env.VUE_APP_API_URL}api/recipes/`
const recipeCustomEndpoint = `${process.env.VUE_APP_API_URL}api/recipes-custom/`
const recipeModelEndpoint = `${process.env.VUE_APP_API_URL}api/recipes-model/`

export default {
  namespaced: true,
  state: {
    recipes: [],
    categories: [],
    customRecipes: [],
    filteredRecipes: [],
  },
  getters: {},
  mutations: {
    SET_RECIPES_LIST(state, data) {
      state.recipes = data
    },
    SET_RECIPES_CUSTOM_LIST(state, data) {
      state.customRecipes = data
    },
    SET_RECIPE_MODEL_LIST(state, data) {
      state.categories = data
    },
    UPDATE_RECIPE_CUSTOM_LIST(state, data) {
      let filteredRecipesNew = [...state.filteredRecipes]
      filteredRecipesNew.forEach(element => {
        if (element.code == data.code) {
          element.temp_range = (data.min_temp || data.min_temp == 0) ? `${data.min_temp}ºC to ${data.max_temp}ºC` : `${data.set_point}ºC`;
          element["is_set_point"] = data.set_point ? true : false;
          element.name = data.name
          element.min_temp = data.min_temp
          element.max_temp = data.max_temp
        }
      });
      state.filteredRecipes = filteredRecipesNew;
    },
    PUSH_RECIPE_CUSTOM_TO_LIST(state, data) {
      data["status"] = "";
      data["temp_range"] = (data.min_temp || data.min_temp == 0) ? `${data.min_temp}ºC to ${data.max_temp}ºC` : `${data.set_point}ºC`;
      data["is_set_point"] = data.set_point ? true : false;
      data["mode"] = "off";
      state.filteredRecipes.push(data)
    },
    DELETE_RECIPE_CUSTOM(state, data) {
      let filteredRecipesNew = [...state.filteredRecipes].filter(e => e.id != data)

      state.filteredRecipes = filteredRecipesNew
    },
    SET_FILTERED_RECIPES_LIST(state, data) {
      state.filteredRecipes = data
    },
    UPDATE_STATUS_RECIPE_CUSTOM_LIST(state, data) {
      let recipes_id = data.recipes
      let filteredRecipesNew = [...state.filteredRecipes]
      recipes_id.forEach(id => {
        filteredRecipesNew.forEach(element => {
          if (element.id == id) {
            element.status = "OK";
          }
        });
      });
      state.filteredRecipes = filteredRecipesNew;
    },
  },
  actions: {
    getRecipes({ commit }, { context }) {  //context -> for later adapt with interceptors

      //GET RECIPES
      return context.$http.get(`${recipeEndpoint}`)
        .then(res => commit("SET_RECIPES_LIST", res.data))
    },
    getAllRecipesByDevice({ commit }, { context, device_id }) {  //context -> for later adapt with interceptors
      let params = new URLSearchParams({
        device: device_id ? device_id : "",
      });

      //GET RECIPES
      context.$http.get(`${recipeCustomEndpoint}get-all-by-device/?${params.toString()}`)
        .then(res => commit("SET_FILTERED_RECIPES_LIST", res.data));
    },
    getCustomRecipes({ commit }, { context, device_id }) {  //context -> for later adapt with interceptors

      //FILTERS
      let params = new URLSearchParams({
        device: device_id,
      });

      //GET CUSTOM RECIPES
      context.$http.get(`${recipeCustomEndpoint}?${params.toString()}`)
        .then(res => commit("SET_RECIPES_CUSTOM_LIST", res.data));
    },
    createCustomRecipes({ commit }, { context, data }) {  //context -> for later adapt with interceptors
      //CREATE CUSTOM RECIPES
      context.$http.post(recipeCustomEndpoint, data)
        .then(res => commit("PUSH_RECIPE_CUSTOM_TO_LIST", res.data));
    },
    updateCustomRecipes({ commit }, { context, data, recipe_id }) {  //context -> for later adapt with interceptors

      //Update RECIPES
      context.$http.put(`${recipeCustomEndpoint}${recipe_id}/`, data)
        .then(res => commit("UPDATE_RECIPE_CUSTOM_LIST", res.data));
    },
    getRecipeModel({ commit }, { context }) {  //context -> for later adapt with interceptors

      //GET RECIPES
      context.$http.get(recipeModelEndpoint)
        .then(res => commit("SET_RECIPE_MODEL_LIST", res.data));
    },
    deleteCustomRecipes({ commit }, { context, recipe_id, data }) {  //context -> for later adapt with interceptors

      //Delete RECIPES
      context.$http.delete(`${recipeCustomEndpoint}${recipe_id}/`, data)
        .then(() => commit("DELETE_RECIPE_CUSTOM", recipe_id));
    },
    syncCustomRecipes({ commit }, { context, device }) {  //context -> for later adapt with interceptors
      let data =
      {
        device: device
      }
      //Update RECIPES
      context.$http.post(`${recipeCustomEndpoint}update-s3/`, data);
    },
  },
}
