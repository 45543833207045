export const roles = {
    isClient(user, currentDevicerRoles) {
      let roleList = ["Client"];
      if(user && currentDevicerRoles)
        return roleList.includes(Object.values(user.groups)[0]) && (currentDevicerRoles.viewers.includes(user.id) || currentDevicerRoles.viewers_remote.includes(user.id));
      else
        return false
    },
    isClientRemote(user, currentDevicerRoles) {
      console.log(user, currentDevicerRoles)
      let roleList = ["Client"];
      if(user && currentDevicerRoles)
        return roleList.includes(Object.values(user.groups)[0]) && currentDevicerRoles.viewers_remote.includes(user.id);
      else
        return false
    },
    isTechnicianRemote(user, currentDevicerRoles) {
      console.log(user, currentDevicerRoles)
      let roleList = ["Client"];
      if(user && currentDevicerRoles)
        return roleList.includes(Object.values(user.groups)[0]) && (user.id == currentDevicerRoles.technician_remote);
      else
        return false
    },
    isAdminOrTechnician(user, currentDevicerRoles) {
      let roleList = ["Mercatus Admin"];
      if(user.groups)
        return roleList.includes(Object.values(user.groups)[0]) || user.id == this.currentDevicerRoles.technician;
      else
        return false
    },
    isAdmin(user)
    {
      let roleList = ["Mercatus Admin"]
      if(user.groups)
        return roleList.includes(Object.values(user.groups)[0])       
      else
        return false
    },
    isDistributor(user){
      let roleList = ["Distributor"];
      if(user.groups)
        return roleList.includes(Object.values(user.groups)[0]);
      else
        return false
    }
 }