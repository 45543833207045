import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  faqData: {
    terms: {
      title: 'General',
      subtitle: '',
      qandA: [
        {
          question: 'What are the requirements to use Dialog?',
          ans: 'You will need the following: \n • A Mercatus SENSE equipment connected to a Wi-Fi network \n • A PC/tablet/smartphone to access Dialog Web App via QR-code or link.',
        },
        {
          question: 'How to connect and synchronize my equipment for the first time?',
          ans:'On Dialog you can have multiple equipment synchronized in the same account. \n \n Use the QR-codes tag and follow the three steps, then register an account (first time users) or login to your account. Synchronization is immediate. \n \n 💡Tip: We supplied two QR-code tags, one inside the equipment next to the rating plate and another together with the instructions manual. In case you can’t find the QR-code tags please contact your supplier.',
        },
        {
          question: 'How can I contact the support team?',
          ans:"📝 Use the contact form in the Contact tab of the website \n \n <a href=''><b> Go to contact form <b/> <a>",
        },
      ],
    },
    privacy: {
      title: 'Account',
      subtitle: '',
      qandA: [
        {
          question: 'How do I create my account?',
          ans:'Creating an account on Dialog will only take a few minutes. When accessing Dialog for the first time, fill-in the registration form, validate your e-mail and you’re up and running.',
        },
        {
          question:'How to access Dialog Web App?',
          ans:"Scan the QR-code 3️⃣   or type the link provided on the QR-codes tag. \n \n Your equipment must to be connected and synchronized with Dialog beforehand, check out how <a href=''><b>here</b></a>. \n \n 💡Tip: We supplied two QR-code tags, one inside the equipment next to the rating plate and another together with the instructions manual. In case you can’t find the QR-code tags please contact your supplier.",
        },
        {
          question: 'What if I lose / forget my login details?',
          ans:'Go https://iot.mercatus.pt/ to and follow these steps: \n \n • Select Forgot Password? \n • Enter your email address to receive a verification code in your email account. \n • Check your email account and copy the verification code. \n • Paste the verification code in the appropriated field and set a new password.',
        },
        {
          question: 'How can I delete my account?',
          ans:"We're sorry you'd like to end your Dialog account! To delete your account, please follow these steps: \n \n • Mouse over your account name or picture at the top-right corner of the screen; \n • In the drop-down menu select My account > Account tab > Profile details section; \n • Scroll down to Delete Account and select the button Deactivate account",
        },
        {
          question: 'How can I change the email address associated with my registered account / subscription?',
          ans:'Log in to your account and proceed as follows: \n \n • Mouse over your account name or picture at the top-right corner of the screen; \n • In the drop-down menu select My account > Account tab > Profile details section; \n • Change the Email account and save.',
        },
      ],
    },
    cookie: {
      title: 'Subscription, Payment & Billing',
      subtitle: '',
      qandA: [
        {
          question: 'How can I subscribe to Dialog Premium?',
          ans:'Log in to Dialog Web App and proceed as follows: \n \n • Mouse over your account name or picture at the top-right corner of the screen; \n • In the drop-down menu select My account > Plans tab; \n • Select the preferred subscription option.',
        },
        {
          question: 'How can I renew my subscription?',
          ans: `All subscriptions are automatically renewed.`,
        },
        {
          question: 'What are the available payment methods?',
          ans: `All payments can be made by credit card or PayPal. By doing so, you authorise us to take payment from you on a recurring annual or monthly basis.`,
        },
        {
          question: 'Where can I find my payment invoice?',
          ans:'To find and download your payment invoice: \n \n • Mouse over your account name or picture at the top-right corner of the screen; \n • In the drop-down menu select My account > Plans tab > Invoice section; \n • Click Download next to the invoice you want to download. \n \n 💡Tip: We also send it to your Email address account.'
        },
        {
          question: 'Where can I add my European Union VAT identification number?',
          ans: `If you require to have a VAT ID on invoice/receipt, please fill it in the payment form during subscription.`,
        },
        {
          question: 'Do the displayed prices include or exclude taxes?',
          ans:
            'All the prices displayed on the subscription options are exclusive of VAT.',
        },
        {
          question: 'How can I cancel my subscription?',
          ans:'You can cancel your subscription at any time and continue to enjoy access during the whole subscribed period. To cancel, proceed as follows: \n \n • Mouse over your account name or picture at the top-right corner of the screen; \n • In the drop-down menu, select My account > Plans tab; \n • Select Cancel subscription button and follow the steps;',
        },
      ],
    },
    faqs: {
      title: 'User Access Management',
      subtitle: '',
      qandA: [
        {
          question: 'Who is the equipment administrator?',
          ans:
            'The first user who synchronises the equipment to Dialog becomes the administrator. \n However the administrator can choose to reassign that role to another user.',
        },
        {
          question: 'How does the administrator allow other users to access the equipment?',
          ans:'• Go to User Management menu; \n • The pending access requests will appear on the top of the page; \n • Press ✔️ to accept or ❌ to deny access; \n • If you accept, the user will receive a warning to inform that you accepted his request.',
        },
        {
          question: 'How does the administrator remotely share access to the equipment?',
          ans:'If you would like to share access to your equipment with new users, proceed as follows: \n \n • Go to User Management menu; \n • Copy the available link and share it with the desired new user; \n • The new user now needs to connect to Dialog through the shared link; \n • Return to User Management menu; \n • The pending access requests will appear on the top of the page; \n • Press ✔️ to accept or ❌ to deny access; \n • If you accept, the user will receive a warning to inform that you accepted his request.',
        },
        {
          question: 'How many users can connect to each equipment?',
          ans:
            'In Dialog there is no limit on the number of users. However, each user has to be authorised by the equipment administrator.',
        },
      ],
    },
  },
}

mock.onGet('/faq/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    // eslint-disable-next-line arrow-body-style
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
