import Vue from 'vue'

// axios
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import Router from '@/router'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosIns.interceptors.request.use(
  config => {
    // Get token from localStorage
    const accessToken = useJwt.getToken();
    // If token is present add it to request's Authorization Header
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${useJwt.jwtConfig.tokenType} ${accessToken}`
    }
    return config
  },
  error => Promise.reject(error),
)

// Add request/response interceptor
axiosIns.interceptors.response.use(
  response => response,
  error => {
    const { config, response } = error
    const originalRequest = config

    if (response && response.status === 401) {
      if (!useJwt.isAlreadyFetchingAccessToken) {
        useJwt.isAlreadyFetchingAccessToken = true
        useJwt.refreshToken().then(r => {
          useJwt.isAlreadyFetchingAccessToken = false

          // Update accessToken in localStorage
          useJwt.setToken(r.data.access)
          //useJwt.setRefreshToken(r.data.refreshToken)

          useJwt.onAccessTokenFetched(r.data.access)
        }).catch(err => {
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')

          // Reset ability
          //ability.update(initialAbility)

          // Redirect to login page
          Router.push({ name: 'auth-login' })
        })
      }
      return new Promise(resolve => {
        useJwt.addSubscriber(accessToken => {
          // Make sure to assign accessToken according to your response.
          // Check: https://pixinvent.ticksy.com/ticket/2413870
          // Change Authorization header
          originalRequest.headers.Authorization = `${useJwt.jwtConfig.tokenType} ${accessToken}`
          resolve(axiosIns(originalRequest))
        })
      })
    }
    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
