import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import devices from './devices'
import users from './users'
import location from './location'
import VuexPersist from 'vuex-persist';
import recipes from './recipes'
import alerts from './alerts'
import plans from './plans'
import metrics from './metrics'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: state => ({
    location: state.location
  }),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})


export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    devices,
    users,
    location,
    recipes,
    alerts,
    plans,
    metrics
  },
  strict: process.env.DEV,
  plugins: [vuexLocalStorage.plugin]
})
