const usersEndpoint = `${process.env.VUE_APP_API_URL}api/auth/users/`
const updateLocationsEndpoint = `${process.env.VUE_APP_API_URL}api/auth/custom-user/update-location/`
const requestEndpoint = `${process.env.VUE_APP_API_URL}api/requests/`
const groupsEndpoint = `${process.env.VUE_APP_API_URL}api/auth/groups/`
const pasgesEndpoint = `${process.env.VUE_APP_API_URL}api/auth/pages/`
const feedbackEndpoint = `${process.env.VUE_APP_API_URL}api/auth/feedback/`
const userManagementEndpoint = `${process.env.VUE_APP_API_URL}api/requests/list-users/`
const userAlertsEndpoint = `${process.env.VUE_APP_API_URL}api/auth/alert/`
const updateDetailEndpoint = `${process.env.VUE_APP_API_URL}api/auth/custom-user/update-detail/`


export default {
  namespaced: true,
  state: {
    permissionUserList: [],
    activeUserList: [],
    currentUser: {},
    listGroups: [],
    currentUserShowModals: [],
    feedbackUsersList: [],
    userAlerts: {},
    countryList:[]
  },
  getters: {},
  mutations: {
    SET_PERMISSION_USER_LIST(state, data) {
      state.permissionUserList = data
    },
    SET_STATUS_REQUEST(state, id, result) {
      let index = state.permissionUserList.findIndex(request => request.id == id)
      state.permissionUserList.splice(index, 1);
    },
    SET_ACTIVE_USER_LIST(state, data) {
      state.activeUserList = data
    },
    SET_CURRENT_USER(state, data) {
      state.currentUser = data
    },
    SET_LIST_GROUPS(state, data) {
      state.listGroups = data
    },
    SET_CURRENT_USER_MODAL_PAGES(state, data) {
      state.currentUserShowModals = data
    },
    SET_PAGE_TRUE(state, page) {
      let currentUserShowModals = state.currentUserShowModals
      currentUserShowModals[page] = true
    },
    SET_USERS_FEEDBACK(state, data) {
      state.feedbackUsersList = data
    },
    SET_USER_ALERTS(state, data) {
      state.userAlerts = data
    },
    SET_COUNTRY_LIST(state, data) {
      state.countryList = data
    },
    SET_UPDATE_CURRENT_USER(state, data){
      let current = state.currentUser
      let user = {...current, ...data}
      state.currentUser = user
    }
  },
  actions: {
    postRegisterUser({ commit }, { context, data }) {  //context -> for later adapt with interceptors
      //CREATE USER
      return context.$http.post(usersEndpoint, data)
    },
    getUserList({ commit }, { context }) {  //context -> for later adapt with interceptors

      //CREATE USER
      return context.$http.get(userManagementEndpoint)
        .then(res => commit("SET_ACTIVE_USER_LIST", res.data));
    },
    getPermissionUserList({ commit }, { context, status }) {  //context -> for later adapt with interceptors
      let params = new URLSearchParams({
        approve: status
      });

      //GET PERMISSION USER LIST 
      return context.$http.get(`${requestEndpoint}?${params.toString()}`)
        .then(res => commit("SET_PERMISSION_USER_LIST", res.data));

    },
    postSolveRequest({ commit }, { context, id, result, role = null }) {  //context -> for later adapt with interceptors
      let data = { approved: result, role: role }
      //GET USER LIST 
      return context.$http.post(`${requestEndpoint}${id}/solve/`, data)
        .then(() => commit("SET_STATUS_REQUEST", id, result));
    },
    getCurrentUser({ commit }, { context }) {
      return context.$http.get(`${usersEndpoint}me/`)
        .then(res => commit("SET_CURRENT_USER", res.data));
    },
    postActivationUser({ commit }, { context, data }) {

      return context.$http.post(`${usersEndpoint}activation/`, data)
    },
    reSendActivation({ commit }, { context, email }) {

      let data = { email: email }

      return context.$http.post(`${usersEndpoint}resend_activation/`, data)
    },
    postForgotPassword({ commit }, { context, email }) {

      let data = { email: email }

      return context.$http.post(`${usersEndpoint}reset_password/`, data)
    },
    postResetPassword({ commit }, { context, data }) {

      return context.$http.post(`${usersEndpoint}reset_password_confirm/`, data)
    },
    postNewPassword({ commit }, { context, data }) {

      return context.$http.post(`${usersEndpoint}set_password/`, data)
    },
    getListGroups({ commit }, { context, data }) {
      return context.$http.get(`${groupsEndpoint}`, data)
        .then(res => commit("SET_LIST_GROUPS", res.data));

    },
    postJoinGroup({ commit }, { context, data }) {

      return context.$http.post(`${groupsEndpoint}join/`, data)

    },
    getCurrentUserPages({ commit }, { context }) {
      return context.$http.get(`${pasgesEndpoint}get-by-user/`)
        .then(res => commit("SET_CURRENT_USER_MODAL_PAGES", res.data));

    },
    setPageView({ commit }, { context, data, id }) {
      return context.$http.patch(`${pasgesEndpoint}${id}/`, data)
        .then(() => commit("SET_PAGE_TRUE", Object.keys(data)));

    },
    postFeedback({ commit }, { context, data }) {
      return context.$http.post(`${feedbackEndpoint}`, data)
    },
    deleteUser({ commit }, { context, data }) {
      return context.$http.delete(`${usersEndpoint}me/`, { data: data })
    },
    getUsersFeedback({ commit }, { context }) {
      return context.$http.get(`${feedbackEndpoint}`)
    },
    getUsersAlerts({ commit }, { context }) {
      return context.$http.get(`${userAlertsEndpoint}get-detail/`)
        .then(res => commit("SET_USER_ALERTS", res.data));
    },
    updateUsersAlerts({ commit }, { context, data, id }) {
      return context.$http.put(`${userAlertsEndpoint}${id}/`, data)
    },
    updateLocations({ dispatch }, { context, data }) {
      return context.$http.patch(`${updateLocationsEndpoint}`, {
        location_image: data
      })
        .then(() => dispatch("getCurrentUser", { context }));
    },
    updateUserData({commit}, {context, data}){
      return context.$http.put(updateDetailEndpoint, data)
    }
  },
}
