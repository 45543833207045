import mock from './mock'

/* eslint-disable import/extensions */

// JWT
import './jwt'

// Table
import './data/extensions/good-table'
import './data/card/card-statistics'
import './data/card/card-analytics'

// Company
import './data/apps/calendar'
import './data/apps/user'

// dashboard
import './data/dashboard/analytics'

// pages
import './data/pages/faq-data'
import './data/pages/faq-data-pt'
/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
