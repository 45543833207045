const schdeule_endpoint = `${process.env.VUE_APP_API_URL}api/metric/schedule`

export default {
  namespaced: true,
  state: {
    schedule_report:{},
  },
  getters: {},
  mutations: {
    SET_SCHEDULE(state, data) {
      state.schedule_report = data
    },
    SET_FREQUENCY(state, data){
      state.schedule_report["frequency"] = data
    },
    SET_EMAIL_LIST(state, data){
      state.schedule_report["users_to_send"] = data
    },
    SET_WINDOW(state, data){
      state.schedule_report["window"] = data
    }
  },
  actions: {
    getSchedule({ commit }, { context, device_id }) {
      let params = new URLSearchParams({
        device: device_id,
      });

      return context.$http.get(`${schdeule_endpoint}/get-schedule?${params.toString()}`)
      .then(res => commit("SET_SCHEDULE", res.data))
    },
    createtSchedule({ commit }, { context, data }) {
      return context.$http.post(`${schdeule_endpoint}/create-schedule/`, data)
      .then(res => commit("SET_SCHEDULE", res.data))
    },
  },
}
