export default [
    // *===============================================---*
    // *--------- USER ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/company/users/edit/:id',
        name: 'apps-users-edit',
        component: () => import('@/views/company/user/users-edit/UsersEdit.vue'),
    },
    {
        path: '/company/payment',
        name: 'payment-detail',
        component: () => import ('@/views/company/user/payment/Payment.vue'),
    },
    {
        path: '/company/user-plan',
        name: 'user-plan',
        component: () => import('@/views/company/user/users-edit/consulta-plans/UserConsulta.vue'),
    },
]