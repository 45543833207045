export default [
  {
    path: '/device/:id',
    name: 'device',
    component: () => import('@/views/device/MainDevice.vue'),
  },
  {
    path: '/remote',
    name: 'remote',
    component: () => import('@/views/remote/Remote-1/Remote.vue'),
  },
  {
    path: '/remote-2',
    name: 'remote-2',
    component: () => import('@/views/remote/Remote-2/Remote2.vue'),
  },
  {
    path: '/remote/:id',
    name: 'remote-details',
    component: () => import('@/views/remote/Remote-2/Remote2.vue'),
  },
  {
    path: '/device/:id/alerts',
    name: 'device-alerts',
    component: () => import('@/views/alerts/Alerts.vue'),
  },
  {
    path: '/alerts/edit',
    name: 'alerts-edit',
    component: () => import('@/views/alerts/AlertsInfoEdit.vue'),
  },
]
